import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { useLocation } from '@reach/router';
import { Box } from '@mui/material';

/*---CVT---*/
import {
  CvtColorSchema,
  DeviceSizes,
  CvtBasicTheme,
} from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { BulletList } from '@conventic-web/internal.cvt.data-display.bullet-list';
import { Hexagon } from '@conventic-web/internal.cvt.data-display.hexagon';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import PageblockPhotoHalf from '../../components/pageBlockPhotoHalf';
import PageblockIconsFlex from '../../components/pageBlockIconsFlex';

/*---Objects---*/
import {
  PaddingObj,
  DiagonalObj,
  IconComponents,
} from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const DevelopmentPageTemplate = ({
  title,
  subtitle,
  image,
  developmentOverview,
  developmentMobileLoesungen,
  developmentBullets,
  developmentStory,
  developmentSpotlight,
}: {
  title: string;
  subtitle: string;
  image: any;
  developmentOverview: any;
  developmentMobileLoesungen: any;
  developmentBullets: any;
  developmentStory: any;
  developmentSpotlight: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h2>{developmentOverview.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(developmentOverview.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
      <h2>{developmentMobileLoesungen.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(developmentMobileLoesungen.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: CvtColorSchema.bgDarkGray,
          color: CvtColorSchema.white,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {developmentBullets.bulletlist.map((item: any) => {
            return (
              <>
                <h3>{item.headline}</h3>
                <HtmlContent
                  dangerouslySetInnerHTML={md.render(item.content)}
                  style={CvtBasicTheme.htmlContent.general}
                />
              </>
            );
          })}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(developmentBullets.content)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>

      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: CvtColorSchema.bgGray,
          color: CvtColorSchema.fontDarkGray,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={developmentStory.image}
            alt="Unternehmensgruppe"
            width="200px"
            height="auto"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2>{developmentStory.headline}</h2>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(developmentStory.content)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!developmentSpotlight.image.childImageSharp
              ? developmentSpotlight.image.childImageSharp.fluid.src
              : developmentSpotlight.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h3
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {developmentSpotlight.subheadline}
        </h3>
        <h2
          style={{
            color: CvtColorSchema.fontBlack,
          }}
        >
          {developmentSpotlight.headline}
        </h2>
        <HtmlContent
          dangerouslySetInnerHTML={md.render(developmentSpotlight.content)}
          style={CvtBasicTheme.htmlContent.general}
        />
      </div>
    </section>
  );
};

DevelopmentPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  developmentOverview: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  developmentMobileLoesungen: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  developmentBullets: PropTypes.shape({
    content: PropTypes.string,
    bulletlist: PropTypes.array,
  }),
  developmentStory: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
  developmentSpotlight: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
};

const DevelopmentPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  const styles = {
    container: {
      bulletlist: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          [`@media (max-width: ${DeviceSizes.laptop})`]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          },
        },
        bullets: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          margin: '0 1em 0 0',
          [`@media (max-width: ${DeviceSizes.laptop})`]: {
            margin: '0 0 3em 0',
          },
        },
        content: {
          width: '40%',
          [`@media (max-width: ${DeviceSizes.laptop})`]: {
            width: '100%',
          },
        },
      },
    },
    hexagon: {
      base: {
        color: CvtColorSchema.white,
        fontSize: 100,
      },
      links: {
        textDecoration: 'none',
        color: CvtColorSchema.fontDarkGray,
      },
      subheadline: {
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: '0.5em',
        width: '100%',
      },
    },
  } as const;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />

      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <BlockStandard
        headline={frontmatter.developmentOverview.headline}
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.developmentOverview.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
      <BlockStandard
        headline={frontmatter.developmentMobileLoesungen.headline}
        bgColor={CvtColorSchema.bgGray}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={6}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.developmentMobileLoesungen.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <br />
        <br />
        <PageblockIconsFlex>
          <Link
            to={SYSTEM_STRINGS.LINKS.INTERN.MOBILE_LOESUNGEN}
            style={styles.hexagon.links}
          >
            <Hexagon
              componentIcon={IconComponents.AppSettingsAlt}
              bgColor={CvtColorSchema.yellow}
              styleComponentIcon={styles.hexagon.base}
              size={6}
              alt={'AppSettingsAlt'}
              clickable={true}
              invert={false}
              rotate={false}
              margin={'30%'}
            />

            <Box component="div" sx={styles.hexagon.subheadline}>
              {SYSTEM_STRINGS.LINKS.NAME.MOBILE_LOESUNGEN}
            </Box>
          </Link>
        </PageblockIconsFlex>
      </BlockStandard>

      <BlockStandard
        bgColor={CvtColorSchema.bgDarkGray}
        padding={PaddingObj.PaddingIdle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={5}
        color={CvtColorSchema.white}
      >
        <Box component="div" sx={styles.container.bulletlist.root}>
          <Box component="div" sx={styles.container.bulletlist.bullets}>
            <BulletList
              list={frontmatter.developmentBullets.bulletlist}
              alignBulletPoints={'right'}
            />
          </Box>
          <Box component="div" sx={styles.container.bulletlist.content}>
            <HtmlContent
              dangerouslySetInnerHTML={md.render(
                frontmatter.developmentBullets.content
              )}
              style={CvtBasicTheme.htmlContent.general}
            />
          </Box>
        </Box>
      </BlockStandard>
      <PageblockPhotoHalf
        headline={frontmatter.developmentStory.headline}
        bgcolor={CvtColorSchema.bgGray}
        indexZ="4"
        imgposition="left"
        image={
          !frontmatter.developmentStory.image.childImageSharp &&
          frontmatter.developmentStory.image.extension === 'jpg'
            ? frontmatter.developmentStory.image.publicURL
            : frontmatter.developmentStory.image.publicURL
        }
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.developmentStory.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </PageblockPhotoHalf>
      <BlockStandard
        headline={frontmatter.developmentSpotlight.headline}
        subheadline={frontmatter.developmentSpotlight.subheadline}
        bgImage={
          !frontmatter.developmentSpotlight.image.childImageSharp &&
          frontmatter.developmentSpotlight.image.extension === 'svg'
            ? frontmatter.developmentSpotlight.image.publicURL
            : null
        }
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={3}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontBlack}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.developmentSpotlight.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
    </Layout>
  );
};

DevelopmentPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DevelopmentPage;

export const developmentPageQuery = graphql`
  query DevelopmentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        developmentOverview {
          content
          headline
        }
        developmentMobileLoesungen {
          headline
          content
        }
        developmentBullets {
          content
          bulletlist {
            headline
            text
          }
        }
        developmentStory {
          headline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        developmentSpotlight {
          headline
          subheadline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageTitle
        pageDescription
        subtitle
        title
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
